:root {
    --accent: #AEBFF1;
    --accent2: #AEBFF1;
}

.footer-container {
    background-color: var(--accent);
    padding: 2rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    padding-top: 1rem;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-link-wrapper {
    display: contents;
    alignment: right;
    align-items: flex-end;
    flex-direction: row;
    justify-content: flex-end;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
}

.footer-link-items h2 {
    margin-bottom: 16px;
}

.footer-link-items > h2 {
    color: #000000;
}

.footer-link-items a {
    color: #000000;
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
    color: var(--accent);
    transition: 0.3s ease-out;
}

.footer-email-form h2 {
    margin-bottom: 2rem;
}

.footer-input::placeholder {
    color: #000000;
}

/* Social Icons */
.social-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.social-icon-link-foot {
    color: #000000;
    font-size: 36px;
    margin: 0 12px;
}

.social-icon-link {
    color: black;
    font-size: 36px;
    margin: 0 12px;
}

.social-icon-link:hover {
    color: var(--accent2);
    //position: absolute;
    transform: translateY(-15%);
    transition: all 0.2s ease-out;
}

.social-logo {
    color: #000000;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}


.social-media {
    max-width: 1000px;
    width: 100%;
}

.vertical{
    flex-direction: row;
    width: available;
    align-items: center;
    align-content: center;
    vertical-align: center;
    margin-top: 16px;
    justify-self: start;
    flex-grow: 1;
    height: border-box;
}

.social-media-wrap {
    display: flex;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    text-align: center;
    justify-content: center;
}

.spacing {
    display: flex;
    justify-content: space-between;
    width: 240px;
}

.website-rights {
    color: #000000;
    margin: 16px;
    text-align: center;
    font-size: 18px;
}

.updates {
    color: #000000;
    margin: 24px;
    margin-top: -8px;
    text-align: center;
    font-size: 16px;
}

@media screen and (max-width: 930px) {
    .footer-links {
        flex-direction: row;
        padding-top: 0rem;
    }

    .social-icon-link:hover {
        font-size: 36px;
    }

    .btn {
        width: 100%;
    }

    .footer-link-wrapper {
        flex-direction: column;
    }

    .social-media-wrap {
        flex-direction: column;
    }

    .vertical {
        flex-direction: column;
        flex-grow: 1;
    }

}