:root {
    --background: #ffffff;
    --text: #000000;
    --heading: #182039;
    --accent: #AEBFF1;
    --accent3: #AEBFF1;
}

/* generally use 24px for headers, 16px for body, 14px for smaller 
20px for card headers*/

body {
    background-color: var(--accent);
    transform-origin: top;
}

ul.custom-list {
    list-style: none; /* Remove default bullets */
    padding-left: 0; /* Remove default padding */
    font-weight: normal; /* Ensure text is not bold */
}

ul.custom-list li {
    position: relative; /* Set position for list items */
    padding-left: 25px; /* Indent the list items */
    font-weight: normal;
    font-family: 'Mukta', sans-serif;
}

ul.custom-list li::before {
    content: '✦'; /* Star emoji */
    position: absolute; /* Position the star */
    left: 0; /* Place the star in the indent space */
    top: 0px; /* Vertically center the star */
    transform: translateY(0%); /* Adjust for perfect centering */
    font-weight: normal;
    
}

highlight {
    color: var(--text);
    background-color: var(--accent, yellow); /* Highlight background color */
    font-family: 'Courier New', Courier, monospace; /* Code-like font */
    padding: 0.2em; /* Optional padding to make the highlight stand out more */
    border-radius: 5px; 
    font-weight: normal;
}

code {
    color: var(--text);
    background-color: var(--accent, yellow); /* Highlight background color */
    font-family: 'Courier New', Courier, monospace; /* Code-like font */
    padding: 0.2em; /* Optional padding to make the highlight stand out more */
    border-radius: 5px; 
    font-weight: normal;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Josefin Sans', sans-serif;

}

h1{
    font-size: 24px;
}

h2{
    font-family: 'Mukta', sans-serif;
    font-size: 16px;
}

h3{
    font-size: 16px;
    display: contents;
}

video {
    max-width: 1000px;
    width: 100%;
    align-self: center;

}

.center-div {
    align-self: center;
    align-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}

iframe {
    align-items: start;
    flex-direction: row;
    height: 500px;
}

.horizontal-container {
    display: flex;
    flex-direction: row;
}
.vertical-container {
    display: flex;
    flex-direction: column;
}

.home-heading {
    font-size: 24px;
    text-align: center;
    padding-bottom: 20px;
    color: var(--heading);
    background-color: var(--background);
}

.page-title-heading{
    font-size: 24px;
    text-align: center;
    padding-bottom: 20px;
    color: var(--heading);
    background-color: var(--background);
}

.page-bottom-heading {
    font-size: 32px;
    text-align: center;
    padding-bottom: 20px;
    color: var(--heading);
    /* background-color: var(--background); */
}

.home-sub-heading {
    font-size: 16px;
    text-align: center;
    margin: -1rem;
    color: var(--heading);
}

.flashlight-text {
    font-size: 32px;
    text-align: center;
    padding-bottom: 20px;
    color: black;
    background-color: rgba(0, 0, 0, 0);
}
bullet-heading {
    font-size: 24px;
}
.sub-heading {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}

.page-heading {
    font-size: 24px;
    text-align: center;
}

.home-text {
    font-size: 24px;
    text-align: center;
    line-height: 1.3;
    font-family: 'Mukta', sans-serif;
}

.page-content {
    padding: 4rem;
    background: var(--background);
}

.general-image {
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    max-width: 515px;
    width: 100%;
    opacity: 0;
    animation: fadeEaseLeft 2s forwards;
}


.profile-image {
    top: 50%;
    left: 0;
    border-radius: 20px;
    box-shadow: 0 0 20px var(--accent);
    transform: translate(-50%, -50%);
    object-fit: cover;
    max-height: 500px;
    max-width: 400px;
    width: 100%;
    opacity: 0;
    animation: fadeEaseLeft 2s forwards;
}

@keyframes fadeEaseLeft {
    0% {
        opacity: 0;
        transform: translate(50%, 0);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes fadeEaseRight {
    0% {
        opacity: 0;
        transform: translate(0, 0);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.fade-in-text {
    opacity: 0;
    animation: fadeEaseRight 2s forwards;
    text-align: left;
    margin: 0;
    line-height: 1.3;
    color: var(--text);

}

.fade-in-subtext {
    opacity: 0;
    animation: fadeEaseRight 2s forwards;
    text-align: left;
    margin: 0;
    line-height: 1.3;
    color: var(--text);
    padding-left: 80px;
}

.content {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    background-color: var(--accent);
    padding: 75px;
}


.content-abt {
    display: flex;
    flex-direction: row;
    /*flex-wrap: wrap;  Allow items to wrap on smaller screens */
    justify-content: center;
    align-items: flex-start; /* Align items to the top */
    background-color: var(--background);
    /* padding: 0rem 75px 50px; */
    padding-right: 0rem;
}

.content-desc {
    display: flex;
    flex-direction: row;
    /*flex-wrap: wrap;  Allow items to wrap on smaller screens */
    justify-content: center;
    align-items: flex-start; /* Align items to the top */
    background-color: var(--background);
    padding: 0px 250px 50px;
    text-align: left;
}

.content-abt-padding {
    padding:0;
}

.free-space {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    justify-content: center;
    align-items: flex-start; /* Align items to the top */
    background-color: var(--background);
}


/*about*/

.image-container {
    flex: 0 0 50%; /* Take up to 50% of the available space */
    max-width: 50%; /* Limit the maximum width of the image */
    padding: 2rem;
    text-align: center;
    font-size: 2rem;
}

.projdesc-image-container {
    display: flex; /* Enable flexbox layout */
    justify-content: space-between; /* Distribute space evenly between images */
    gap: 2rem; /* Add space between images */
    padding: 2rem;
    text-align: center;
    font-size: 2rem;
    font-weight: normal;
}
.projdesc-image-container img {
    max-height: 250px;
    width: 100%;
    object-fit: cover;
    font-weight: normal;
}
.projdesc-image-container .image-with-caption {
    max-width: 250px;
    flex: 1 1 250px;
    text-align: center;
}

.projdesc-image-container .image-with-caption2 {
    max-height: 250px;
    flex: 1 1 250px;
    text-align: center;
}

.projdesc-image-container .wide-image-with-caption {
    width: 550px;
    //flex: 1 1 550px;
    text-align: center;
}

.projdesc-image-container .caption {
    font-style: italic; /* Italicize the caption text */
    font-size: 14px; /* Adjust the font size of the caption */
    font-weight: normal;
    font-family: 'Mukta', sans-serif;
    max-width: 100%; /* Ensure the caption stays within the image width */
    word-wrap: break-word; /* Allow long words to wrap */
    text-align: center; /* Optional: keeps the caption centered */
}

.text-container {
    flex: 0 0 60%; /* Take up to 50% of the available space */
    padding-top: 3rem;
    /*padding-right: 2rem;
    padding-left: 1rem;*/
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack elements vertically */
    justify-content: space-between;
    background-color: var(--background);
}

.single-col-text-container-home {
    max-width: 750px;
    align-content: center;
}

.single-col-text-container {
    max-width: 750px;
    align-content: center;

}

.resume-container {
    display: flex;
    vertical-align: center;
    align-items: center;
}

.resume-text-container{
    width: min-content;
}


/*resume*/

.resume-link {
    color: var(--text);
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    padding-top: 20px;
    opacity: 0;
    font-family: 'Mukta', sans-serif;
    animation: fadeEaseRight 2s forwards;
    transition: 0.3s ease;
}

.resume-link:hover {
    color: var(--accent2);
}

.social-logo {
    color: var(--text);
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.container {
    position: relative;
    width: 100%;
    height: 500px;
    background-color: var(--accent2);
    color: var(--text);
    text-align: center;
    overflow: hidden;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.modeling-images {
    width: 1000px;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: fadeEaseLeft 2s forwards;
    margin: 1rem 0 1rem;
}

.custom-rounded-rectangle {
    display: inline-block;
    padding: 8px 10px; 
    border-radius: 8px; 
    /*border: 2px solid var(--nav-background);  Border color */
    margin: 8px 4px 0px;
    font-size: 12px;
    background-color: black;
    /* box-shadow: 0 0 20px rgb(149, 156, 248); */
    color: var(--nav-background);
    align-self: flex-end;
}

@media screen and (max-width: 930px) {
    iframe {
        width: 100%;
        margin-top: -2.25rem;
        margin-bottom: -2.25rem;
    }

    .content-abt {
        flex-direction: column; /* Stack items on top of each other */
        align-items: center;
    }

    .profile-image {
        /* width: 50%; */
        border-radius: 20px;
        margin-top: 1rem;
    }

    .image-container {
        max-width: 390px;
    }

    .text-container {

        padding: 0;
    }

    .single-col-text-container-home {
        padding-bottom: 1rem;
        margin-top: -2rem;
    }

    .single-col-text-container {
        padding-bottom: 1rem;
    }

    .content-abt{
        padding: 0;
    }

    .content-abt-padding {
        margin: 3rem 4rem 0;

    }

    .home-heading {
        padding: 2rem 0 2rem;
    }

    .page-bottom-heading{
        padding: 0;
        padding-top: 2rem;
    }

    .home-sub-heading {
        padding-top: 3rem;
    }

    .modeling-images {
        width: 100%;
    }


}

.text-description {
    position: absolute;
    text-align: center;
    color: var(--text);
    transition: opacity 0.3s ease-in-out;
    padding: 75px;

}

.container:hover .hover-area {
    width: 500px;
    height: 500px;
    opacity: 1;
}

.hover-area {
    position: absolute;
    top: -50%;
    left: -250px;
    border-radius: 20px;
    background: radial-gradient(circle at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%);
    opacity: 0;
    pointer-events: none;

}

.flashlight-off .hover-area {
    display: none;
}

.flashlight-off {
    background-color: black;
}


@media only screen and (min-width: 2000px) {


    .single-col-text-container-home {
        margin: 0 1000px;
    }

    .single-col-text-container {
        margin: 0 500px;
    }
}

.cards__item__info .rounded-rectangle-container {
    align-self: flex-end;
}

