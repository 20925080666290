:root {
    --nav-background: #AEBFF1;
    --nav-text: #000000;
    --nav-hilighted: white;
}
.navbar {
    /*background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);*/
    background: var(--nav-background);
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    max-width: 1500px;
}

.navbar-logo {
    color: var(--nav-text);
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 28px;
    display: flex;
    align-items: center;

    position: absolute;
    top: 0;
    left: 20%;
    transform: translate(0%, 24px);
    z-index: 1;
}

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 16px;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;

    position: absolute;
    top: 0;
    right: 20%;
}

.nav-item {
    height: 70px;
}


.nav-links {
    color: var(--nav-text);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    /* font-size: 20px; */
    /* margin-top: 5px; */
}

.nav-links:hover {
    position: relative;
    transform: translateY(-5%);
    transition: all 0.2s ease-out;
}

.nav-links:hover::after {
    content: "✦";
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%) translateY(-25%);
    transition: all 0.2s ease-out;
    font-size: 16px;
    color: white;
}

.nav-links.active {
    position: relative;
}

.nav-links.active::after {
    content: "✦";
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.2s ease-out;
    font-size: 16px;


}

.fa-bars {
    color: var(--nav-text);
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

button {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    font-size: 24px;
}

button:hover {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    font-size: 36px;
    transform: translateY(-5%);
    transition: all 0.2s ease-out;
}

.LD {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    font-size: 24px;
    margin-left: 1rem;
}

.LD:hover {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    font-size: 36px;
    transition: all 0.2s ease-out;
}

.LD-image {
    transition: transform 0.3s ease; /* Add smooth transition effect */
}

.LD:hover .LD-image {
    transform: scale(1.2) translateY(10%);
}

@media screen and (max-width: 930px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 40vh;
        position: absolute;
        background-color: var(--nav-background);
        top: 65px;
        left: -100%;
        opacity: 1;
        //transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: var(--nav-background);
        left: 0;
        opacity: 1;
        //transition: all 0.5s ease;
        z-index: 1;
        bottom: 50px;

    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        /*background-color: #fff;*/
        color: var(--nav-text);
        border-radius: 0;
    }

    .nav-links:hover::after {
        padding-top: 5px;
    }

    .nav-links.active::after {
        padding-top: 5px;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(2rem, 100%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 75%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: var(--nav-text);
        font-size: 2rem;
        position: absolute;
        top: 23px;
        right: 27px;
    }



    .nav-links:hover::after {
        content: "✦";
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.2s ease-out;
        font-size: 16px;
        //color: black;
    }

    .nav-links.active {
        position: relative;
    }

    .nav-links.active::after {
        content: "✦";
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.2s ease-out;
        font-size: 16px;
    }

    button {
        font-size: 36px;
    }

    button:hover {
        background-color: rgba(255, 255, 255, 0);
        border: none;
        transform: translateY(-5%);
        transition: all 0.2s ease-out;
    }

    .LD {
        margin-left: 0;
        margin-top: 2rem;
    }

/*    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: var(--nav-hilighted);
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
        background: var(--nav-hilighted);
        color: var(--nav-text);
        transition: 250ms;
    } */
}