:root {
    --background: #ffffff;
    --text: #000000;
    --card: #EBEFFB;
    --accent: #AEBFF1;
}

.cards {
    background: var(--background);
    padding: 0;
    flex-flow: row;
    align-items: center;
    justify-content: center;
}

h1 {
    text-align: center;
}


.cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;

}

.cards__wrapper {
    position: relative;
}

.cards__items {
    display:flex;
}

.cards__item {
    display: flex;
    flex: 1;
    margin: 1rem 1rem;
    transition: 0s ease;
    flex-flow: column;
    max-height: 400px;
}

.cards__item__link {
    display: flex;
    position: relative;
    flex-flow: column;
    width: 100%;
    height: 300px;
    //background-color: var(--card);
    box-shadow: 0 0 20px var(--accent);
    //-webkit-filter: drop-shadow(0 6px 20px rgb(149, 156, 248));
    //filter: drop-shadow(0 6px 20px rgb(149, 156, 248));
    overflow: hidden;
    text-decoration: none;
    border-radius: 20px;
    transition: .3s ease;
    z-index: 1;
}

.cards__item__pic-wrap {
    position: relative;
    max-height: 500px;
    width: 100%;
    
    padding-top: 100%;
    overflow: hidden;
    margin-top: -300px;
    border-radius: 20px 20px 0px 0px;
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 20px 15px;
    font-size: 16px;
    font-weight: 700;
    color: var(--text);
    background-color: var(--card);
    width: 100%;
    box-sizing: border-box;
    transition: .3s ease;
}

.cards__item__img {
    position: absolute;
    top: -71px; /*video distance from top of card*/
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 200px;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}


.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: 300px;
    background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth transition */
    border-radius: 20px;
}

.overlay-text {
    font-size: 14px;
    line-height: 18px;
    font-family: 'Mukta', sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    transform: translate(-50%, -50%);
    color: white;
    margin-top: 2.rem;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth transition */
}

.cards__item__info {
    padding: 15px 20px 15px;
    flex: 1;
    z-index: 0;
    background-color: var(--card);
    max-height: 66px;
    margin-top: -127px; /*very specific and finnicky adjustments*/
    border-radius: 0px 0px 20px 20px;
}

.cards__item:hover .overlay {
    opacity: 1; /* Show overlay on hover */
}

.video-container:hover > .overlay {
    opacity: 1; /* Show overlay on hover */
}

.cards__item:hover .overlay-text {
    opacity: 1; /* Show text on hover */
}

.cards__item:hover .cards__item__pic-wrap:after {
    background-color: var(--accent);
}

.cards__item__img:hover {
    transform: scale(1.1);
}

.cards__item:hover .cards__item__info{
    background-color: var(--accent);
}

.cards__item__pic-wrap:hover .cards__item__img {
    transform: scale(1.1);
}

.cards__item:hover .cards__item__img {
    transform: scale(1.1);
}

.cards__item__text:hover .cards__item__img {
    transform: scale(1.1);
}

.cards__item__text {
    color: white;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Mukta', sans-serif;
    opacity: 0;

    margin-top: -2rem;
    transition: opacity 0.3s ease; /* Smooth transition */
}

.cards__item__text__sm {
    color: white;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Mukta', sans-serif;
    opacity: 0;

    margin-top: -3rem;
    transition: opacity 0.3s ease; /* Smooth transition */
}

.cards__item:hover .cards__item__text {
    opacity: 0; /* Show text on hover */
}

.cards__icons {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px 15px 0px 0px;
    flex-direction: row;
    display: flex;
    gap: 35px;
    z-index: 1;
}

.cards__icon {
    color: black;
    size: 16px;
    transition: 0.3s ease;
    margin-left: -20px;
    position: relative;  /* Ensure the element is positioned */
    z-index: 10 !important;
    pointer-events: auto;
}

.cards__icon a {
    pointer-events: auto; /* Make sure it's clickable */
    position: relative;
    z-index: 20;
}

.cards__icon:hover {
    color: white;
    filter: invert(100%);
}



@media only screen and (min-width: 1200px) {
    .content__blog__container {
        width: 84%;
    }

}

@media only screen and (max-width: 1300px) {

    /* .cards__item {
        margin: 2rem;
    }

    .cards__items {
        display: block;
    } */

}

@media only screen and (max-width: 930px) {
    .cards {
        padding: 0;
    }
    .cards__item {
        margin-bottom: 2rem;
    }

    .cards__items {
        display: block;
    }

}
